<template>
  <v-container fluid>
    <base-page-title
      >DUT Creation: Project {{ project.project_name }}</base-page-title
    >
    <v-row dense>
      <v-col cols="12" md="1">
        <base-navigation-button
          tooltipText="Back to Project Index"
          nextRoute="projects"
          navIcon="mdi-chevron-left"
          color="warning"
          route
          :next="false"
        />
      </v-col>
      <v-col cols="12" md="11" class="d-flex justify-center align-center">
        <v-alert dense class="my-3 text-center body-1 font-weight-bold">
          If you need a mutual NDA in place before sharing design files,
          <a target="_blank" href="https://airtable.com/shr4JTpof2apRzf8q"
            >submit a request for quote form</a
          >
          instead of creating a FixturFab Project.
        </v-alert>
      </v-col>
    </v-row>
    <dut-config-form
      @saveData="saveForm"
      :fdID="fixtureDesignId"
      :editMode="false"
    />
  </v-container>
</template>
<script>
import pcbStackup from "pcb-stackup";
import { getFileString } from "@/helpers/basehelper.js";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "DutConfigCreate",
  components: {
    DutConfigForm: () => import("../components/DutConfigForm.vue"),
  },
  computed: {
    ...mapGetters({
      project: "projects/selProject",
      fixtureDesign: "fixturedesign/fixtureDesign",
      gerbers: "dutconfigs/gerberFiles",
    }),
    fixtureDesignId() {
      return Object.keys(this.project.fixture_design_details)[0];
    },
  },
  methods: {
    getFileString,
    ...mapActions({
      createDutConfig: "dutconfigs/saveDutConfigs",
      createGerberFile: "dutconfigs/saveGerberFile",
      getDutConfigs: "dutconfigs/getDutConfigsById",
      patchDutConfig: "dutconfigs/updateDutConfigs",
      fetchFixtureDesign: "fixturedesign/getFixtureDesign",
      patchFixtureDesign: "fixturedesign/patchFixtureDesign",
      initFixtureDesignState: "fixturedesign/initFixtureDesignState",
      initSelectedProject: "projects/clearProject",
      toggleLoading: "ui/loading",
    }),
    async saveForm({ inputData, gerberData }) {
      try {
        const response = await this.createDutConfig(inputData);

        await this.patchFixtureDesign({
          pk: this.fixtureDesign.pk,
          data: { update_invoice: true, update_render: true },
        });

        if (gerberData.gerberFiles.length > 0) {
          this.toggleLoading("Saving Gerber Files");
          await this.saveGerbers({ gerberData, pk: response.pk });
          await this.getDutConfigs(response.pk);
          if (this.gerbers) await this.saveGerbersSvg(response.pk);
          this.toggleLoading();
        }
        
        this.$router.replace(
          "/configure/project/" +
            this.$route.params.id +
            "/dutconfig/" +
            response.pk
        );
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async saveGerbers({ gerberData, pk }) {
      for (let i = 0; i < gerberData.gerberFiles.length; i++) {
        let formData = new FormData();
        let file = new Blob([gerberData.gerberFiles[i].gerber], {
          type: "application/vnd.gerber",
        });
        let filename = gerberData.gerberFiles[i].file;
        formData.append("user", gerberData.user);
        formData.append("project", gerberData.project);
        formData.append("fixture_design", gerberData.fixture_design);
        formData.append("dut_config", pk);
        formData.append("file", file, filename);
        await this.createGerberFile(formData);
      }
    },
    async saveGerbersSvg(pk) {
      try {
        const layers = await Promise.all(
          this.gerbers.map(async (element) => ({
            filename: element.file,
            gerber: await this.getFileString(element.file),
          }))
        );
        const response = await pcbStackup(layers);
        const actionPayload = {
          pk,
          data: { pcb_stackup: JSON.stringify(response) },
        }
        await this.patchDutConfig(actionPayload);
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
  },
};
</script>
